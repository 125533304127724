body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Helvetica Neue', sans-serif,
  monospace;
}

.skill-page {
  max-width: fit-content;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  color: #0073e6;
  border-radius: 10px;
}
.skill-page h3 {
  font-family: 'Helvetica Neue', sans-serif;
  color: #005bb5;
}
.skill-page h4 {
  font-family: 'Helvetica Neue', sans-serif;
  color: #005bb5;
}

.skill-page h5 {
  font-family: 'Helvetica Neue', sans-serif;
  display: inline;
  font-style: italic;
  color: red;
}

.skill-page p {
  font-family: 'Helvetica Neue', sans-serif;
  margin-bottom: 15px;
  color: #555555;
}

.skill-page li {
  font-family: 'Helvetica Neue', sans-serif;
  color: #555555;
  margin-left: 10px;
}

.right-sidebar {
  margin-left: 700px;
  width: 320px;
  min-height: 180px;
}

.Header::before {
  color: darkorange;
}

.container{
  display: flex;
}

.image-container img {
  margin-right: 5px;
}